<script setup lang="ts">
import { focusElement } from 'lib/accessibility'
import type { Product } from 'types/models/product'
import { ITEMS_PER_COLOR_SELECTOR } from '~/configuration/global.configuration'

interface Props {
  product: Product
}

const props = defineProps<Props>()

const gtm = useGTM()
const productStore = useProductStore()
const el = ref(null)
const mounted = useMounted()

const isNotPurchasable = computed(() => props.product.categories.includes('hide'))

const relatedProductsByColor = computed(() => {
  // Fix hydration error as the productStore is not properly hydrated
  if (!mounted.value)
    return undefined

  return productStore.currentProductAndCluster?.slice(0, ITEMS_PER_COLOR_SELECTOR)
})

onMounted(async () => {
  gtm.pushProductDetail({ product: props.product })
  focusElement('#product-page')
})
</script>

<template>
  <article ref="el" class="product-view" :aria-label="product.name">
    <ProductGallery
      :images="product.media.images"
      :name="product.name"
      :sku="product.sku"
      class="product-gallery"
      :aria-label="$t('productImageGallerySection')"
    />
    <div class="info-container">
      <div class="info">
        <ProductDetails :product="product" />
        <div v-if="!isNotPurchasable" class="add-to-card">
          <ProductSizeAndAddToCart :product="product" :sticky="true" />
        </div>
        <ProductColorSelector v-if="relatedProductsByColor && relatedProductsByColor?.length > 1" :products="relatedProductsByColor" />
        <ProductInformation :product="product" />
      </div>
    </div>
  </article>
  <section class="edge">
    <LazyProductViewSuggestions />
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/rules/edges';

$laptop-large: 1180px;

.product-view {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--header-main-height) / 2);

  @media (min-width: $tablet) {
    padding-top: 0px;
  }
}

:deep(.banner) {
  padding: 1.8rem 0 !important;
  .content {
    font-weight: bold;
    font-size: 3.6rem;
    font-style: italic;
    line-height: 5.2rem;
  }
}

.info-container {
  display: contents;
  background-color: var(--gray-lighter);
  padding-bottom: 2.4rem;
}

.add-to-card {
  padding: 0 1.6rem 2rem;
  background-color: var(--gray-lighter);
}
.add-to-card {
  :deep(.size-selector) input::placeholder {
    color: var(--text-secondary);
    font-size: 1.4rem;
  }
}

.edge {
  @include teeth;
  background-color: var(--white);
  margin-top: -1.5rem;
  padding-top: 3rem;
}

@media (min-width: $tablet) {
  .info-container {
    padding-top: max(var(--header-main-height), 10rem);
    display: block;
    grid-area: form;
    position: sticky;
    overflow-y: scroll;
    flex: 1;
    min-width: 37%;
    height: calc(100vh - var(--top-banner-height));
  }

  .info {
    max-width: 70rem;
    min-width: 30rem;
    margin: auto;
  }
  .product-view {
    display: flex;
    flex-direction: row;
    height: calc(100vh - var(--top-banner-height));
  }
  .product-gallery {
    grid-area: gallery;
  }
}
</style>
