export async function useProduct(sku: string) {
  const productStore = useProductStore()
  const { data, error } = await useAsyncData(() => productStore.fetchProductForPDP(sku))

  if (!data.value?.product) {
    console.error('Failed to fetch product', error)
    throw createError({ statusCode: 404, message: 'Product not found' })
  }

  watch(() => data.value, (data) => {
    if (data) {
      productStore.setProductAndAssociations(data.product, {
        recommendations: data.recommendations,
        cluster: data.cluster,
        similar: data.similar,
        id: data.id,
      })
    }
  })

  return {
    product: computed(() => data.value?.product),
    recommendations: computed(() => data.value?.recommendations),
    cluster: computed(() => data.value?.cluster),
    similar: computed(() => data.value?.similar),
  }
}
