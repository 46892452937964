<script setup lang="ts">
import {
  generateAlternateLinks,
  generateCanonicalLink,
  getPrimaryMeta,
  getSEODataFromProduct,
  getSeoHeadData,
  getSeoTitle,
} from 'lib/seo'
import type { WarehouseRegion } from '~/types/models/storefront'

definePageMeta({
  context: 'product',
  isProductPage: true,
})

const {
  public: { baseUrlClient },
} = useRuntimeConfig()
const route = useRoute()
const gtm = useGTM()
const storefrontStore = useStorefrontStore()

const { currentStorefrontCode, allMarkets } = storeToRefs(storefrontStore)
const sku = route.params.sku

if (!sku || typeof sku !== 'string')
  throw createError({ statusCode: 404, message: 'Failed to fetch product' })

const { product } = await useProduct(sku)

if (!product.value)
  throw createError({ statusCode: 404, message: 'Failed to fetch product' })

const seoData = getSEODataFromProduct(product.value)

onMounted(() => {
  window.scrollTo({ top: 0 })
})

onBeforeRouteLeave(() => {
  gtm.pushCloseProduct(route.path)
})

useSeoMeta(getSeoHeadData(seoData, currentStorefrontCode.value))

const notAvailableInWarehouses = computed<WarehouseRegion[]>(() => {
  const stockPerWarehouse = product.value?.amountOfVariantsInStockPerWarehouse

  if (!stockPerWarehouse)
    return []

  return Object.entries(stockPerWarehouse)
    .filter(([_, stock]) => stock === 0)
    .map(([warehouse]) => warehouse.toUpperCase() as WarehouseRegion)
})

const canonicalLink = computed(() => {
  return generateCanonicalLink({
    baseUrlClient,
    route,
  })
})

const canonicalLinkArray = computed(() => {
  return canonicalLink.value ? [canonicalLink.value] : []
})

const seoLinks = computed(() => [
  ...generateAlternateLinks({
    allMarkets: allMarkets.value,
    fullPath: route.fullPath,
    baseUrlClient,
    excludeWarehouse: notAvailableInWarehouses.value,
  }),
  ...canonicalLinkArray.value,
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon.png',
  },
])

useHead({
  title: () => getSeoTitle(seoData, currentStorefrontCode.value, { route }),
  meta: () => (seoData && getPrimaryMeta(seoData.title, seoData.description)) || [],
  link: seoLinks,
})
</script>

<template>
  <main id="product-page">
    <template v-if="product">
      <Jsonld :data="product" />

      <ProductView :product="product" />
    </template>
  </main>
</template>
