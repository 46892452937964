<script setup lang="ts">
import { ITEMS_PER_COLOR_SELECTOR } from '~/configuration/global.configuration'
import type { Product } from '~/types/models/product'

interface Props {
  products: Product[]
}

const props = defineProps<Props>()

const modalStore = useModalStore()
const { showColorSelectorList } = storeToRefs(modalStore)

const itemOutOfStock = computed(() =>
  props.products.some(product => product.stockStatus === 'no-stock'),
)
</script>

<template>
  <div class="color-selector">
    <ProductColorSelectorCards class="products-list" :products="products" :hide-card-info="true" />
    <button
      v-if="products.length > ITEMS_PER_COLOR_SELECTOR - 1"
      :aria-label="$t('colorSelector')"
      class="btn-all"
      data-test="color-selector"
      @click="showColorSelectorList = true"
    >
      <div class="icon-container-wrapper">
        <div class="icon-container">
          <IconBasic class="icon" filename="plus" />
        </div>
      </div>
      <p class="btn-text" :class="{ pushed: itemOutOfStock }">
        {{ $t('all') }}
      </p>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';

.color-selector {
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: 1.6rem 2rem;
  background-color: var(--gray-lighter);
  justify-content: space-between;
}

.btn-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline-offset: -2px;
}

.icon-container-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  height: 4rem;
  width: 4rem;
  background-color: var(--gray-light);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 2rem;
  height: 2rem;
}

.btn-text {
  @include inputText;

  &.pushed {
    padding-bottom: 12px;
  }
}

.products-list {
  // should be grid to preserve spaces when there is few elements
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}
</style>
